@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  /* padding: 0; */
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}

body {
  font-family: "poppins";
  background-color: #f5f5f5;
}


.p-dropdown-item-label {
  font-size: 1rem;
}

.p-dropdown>.p-component>.p-inputwrapper>.p-inputwrapper-filled {
  width: 170px;
}

.scrollbar::-webkit-scrollbar {
  width: 0px;
}

.p-rating .p-rating-item.p-rating-item-active .p-rating-icon {
  color: #F2A227;
}

.p-rating .p-rating-item .p-rating-icon {
  @apply text-[#F2A227] w-[62px] h-[62px] sm-max:w-[2rem]
}

.p-rating .p-rating-item.p-focus {
  box-shadow: none;
}

.applyCode .p-dialog-header-icons {
  @apply !m-[10px]
}

.p-toast .p-toast-message .p-toast-message-content {
  padding: 1rem;
  border-width: 0 0 0 6px;
}

.p-toast-message-content {
  display: flex;
  align-items: flex-start;
}

.p-component {
  font-family: var(--font-family);
  font-feature-settings: var(--font-feature-settings, normal);
  font-size: 1rem;
  font-weight: normal;
}

.p-toast .p-toast-message.p-toast-message-warn {
  background: rgba(255, 242, 226, 0.7);
  border: solid #cc8925;
  border-width: 0 0 0 6px;
  color: #cc8925;
}

.p-toast {
  opacity: 1;
}

.p-toast .p-toast-message {
  margin: 0 0 1rem 0;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.p-toast-message-enter-done {
  transform: none;
}

.p-toast-message {
  backdrop-filter: blur(10px);
}

.p-component,
.p-component * {
  box-sizing: border-box;
}

.p-toast .p-toast-message .p-toast-message-content {
  padding: 1rem;
  border-width: 0 0 0 6px;
}

.p-toast-message-content {
  display: flex;
  align-items: flex-start;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
  margin: 0 0 0 1rem;
}

.p-toast-message-text {
  flex: 1 1 auto;
}

.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
  color: #cc8925;
}

.p-toast .p-toast-message.p-toast-message-error {
  background: rgba(255, 231, 230, 0.7);
  border: solid #ff5757;
  border-width: 0 0 0 6px;
  color: #ff5757;
}

.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
  color: #ff5757;
}

.p-paginator-next{
@apply w-[55px] h-[55px] rounded-full bg-gray-300
}
.p-paginator-prev{
@apply w-[55px] h-[55px] rounded-full bg-gray-300 right-40 absolute md-small:right-28
}

.text-gradient {
  @apply text-transparent bg-clip-text bg-gradient-to-r from-[#1F6AFF] to-[#27BEFA]
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}