.quizhero-main {
    width: 100%;
    height: 100vh;
    display: flex;
  }
  
  .hero-content-section {
    width: 55%;
    height: 100vh;
    padding: 60px 60px 60px 60px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  
  .hero-image-section {
    width: 45%;
    height: 100vh;
  }
  
  .close-button {
    float: right;
  }
  
  .close-btn {
    display: flex;
    gap: 0px;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    float: right;
    padding-top: 20px;
  }
  
  .hero-contents {
    justify-content: center;
  
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  
  .hero-contents h2 {
    font-size: 16px;
    font-weight: 600;
  }
  
  .hero-contents h1 {
    font-size: 58px;
    font-weight: 600;
    line-height: 68px;
    color: #1c36bc;
    max-width: 80%;
  }
  
  .hero-contents p {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
  }
  
  .heding-span {
    color: #1c36bccc;
  }
  
  .lets-start-button {
    margin-top: 2rem;
  }
  
  .lets-start-button button {
    padding: 15px 30px;
    border-radius: 10px;
    font-size: 20px;
    background-color: #1c75bc;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-weight: 600;
  }
  
  .hero-image-section {
    background-image: url("../../Assets/image\ 3\ \(1\).png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .power {
    display:flex;
    align-items: center;
    gap: 10px;
  }
  .u-icon {
    /* width: 8.875rem; */
    height: 3.75rem;
    padding-top: 30px;
    float: right;
    padding-right: 50px;
  }
  @media screen and (max-width: 1520px) {
    body {
      overflow-x: hidden;
    }
    .hero-content-section {
      padding: 30px 30px 30px 30px;
    }
    .hero-contents{
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
    .hero-contents h1 {
      font-size: 52px;
      max-width: 100%;
    }
    .hero-contents p {
      font-size: 16px;
      max-width: 100%;
    }
  }
  @media screen and (max-width: 1300px) {
    body {
      overflow-x: hidden;
    }
    .hero-contents h1 {
      max-width: 100%;
      font-size: 42px;
      line-height: 40px;
    }
    .hero-contents p {
      font-size: 14px;
      max-width: 100%;
    }
    .power img{
      width: 25px;
      height: 25px;
    }
    .power h2{
      font-size: 16px;
    }
    .limited-offer-container{
      @apply py-3 px-3
    }
    .limited-offer-container p{
@apply text-xl
    }
    .start-btn{
      @apply text-xl
    }
  }
  @media screen and (max-width: 1024px) {
    body {
      overflow-x: hidden;
    }
    .hero-content-section {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding: 30px 30px 30px 30px;
    }
    .hero-contents h1 {
      font-size: 50px;
      font-weight: 600;
      line-height: 68px;
      color: #1c36bc;
      max-width: 100%;
    }
    .hero-contents p {
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
      max-width: 100%;
    }
    .lets-start-button button {
      padding: 20px 30px;
      border-radius: 10px;
      font-size: 18px;
      background-color: #1c75bc;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      font-weight: 600;
    }
  }
  @media screen and (max-width: 850px) {
      body {
        overflow-x: hidden;
      }
      .hero-image-section {
          display: none;
        }
        
  .hero-content-section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
      width: 100%;
  }
  .hero-contents h1 {
      font-size: 50px;
      font-weight: 600;
      line-height: 68px;
      color: #1c36bc;
      max-width: 100%;
      text-align: center;
  }
  .hero-contents p {
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
      max-width: 100%;
      text-align: center;
  }
  .lets-start-button {
      margin-top: 2rem;
      display: flex;
      justify-content: center;
  }
  
  }
  
  @media screen and (max-width: 768px) {
    .hero-image-section {
      display: none;
    }
    .hero-content-section {
      width: 100%;
    }
    .hero-contents h1 {
      text-align: center;
    }
    .hero-contents p {
      text-align: center;
      max-width: 100%;
    }
    .lets-start-button {
      margin-top: 4rem;
      display: flex;
      align-items: cener;
      justify-content: center;
    }
    .power{
      display: flex;
      justify-content: center;
    }
  }
  
  @media screen and (max-width: 640px) {
    .hero-image-section {
      display: none;
    }
    .hero-content-section {
      width: 100%;
      padding: 1rem;
    }
    .hero-contents {
      justify-content: center;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .hero-contents h1 {
      font-size: 32px;
      max-width: 100%;
      line-height: 55px;
      text-align: center;
    }
    .hero-contents p {
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
      max-width: 100%;
      text-align: justify;
    }
    .power {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }
    .lets-start-button {
      display: flex;
      margin-top: 8px;
    }
    .lets-start-button button {
      padding: 20px 30px;
    }
  }
